


































import { cancelOrder, confirmReceipt, getOrderList, getPrepay } from '@/api/order';
import { GetPromiseReturn, ProductTypeEnum } from '@/api/type/base';
import getOrderState, { BaseOrderStateFnKey } from '../commFunction/getOrderState';
import BaseDialog from '@/components/Base/BaseDialog/index';
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import { getWechatPrepay } from '@/api/pay';
import wxApi from '@/lib/jsskd';
import { Toast } from 'vant';
import router from '@/router';
import Service from '@/lib/jsskd/service';
type OData = GetPromiseReturn<typeof getOrderList>['payload']['records'][number];
@Component({
  name: 'BaseOrderButtonGroup'
})
export default class extends Vue {
  @Prop({ required: true })
  innerData!: OData;

  @Inject('reload')
  reload!: (orderCode: string) => void;

  get productTypeEnum() {
    return ProductTypeEnum;
  }

  get baseOrderStateFnKey() {
    return BaseOrderStateFnKey;
  }

  get orderState() {
    return getOrderState(this.innerData);
  }

  openService() {
    // return Service.openOnlineService.bind(Service);
    window.location.href = 'tel:17280702720';
  }

  handleClickExpress() {
    this.$router.push({
      name: 'OrderExpress',
      params: { orderCode: this.innerData.orderCode },
      query: { orderImg: this.innerData.skuImgUrl }
    });
  }
  async handleClickConfirmReceipt() {
    await BaseDialog({
      title: '提示',
      message: '请确认是否确认拿到商品'
    });
    await confirmReceipt(this.innerData.orderCode);
    this.reload(this.innerData.orderCode);
  }

  async cancel() {
    await BaseDialog({
      message: '请确认是否取消订单，一旦取消后无法恢复'
    });
    await cancelOrder(this.innerData.orderCode);
    this.reload(this.innerData.orderCode);
  }

  async onPay() {
    const orderCode = this.innerData.orderCode;
    const {
      payload: { prepayId }
    } = await getPrepay(orderCode);
    const { payload } = await getWechatPrepay(prepayId);
    try {
      await wxApi.onPay(payload);
      this.reload(this.innerData.orderCode);
      Toast({
        type: 'success',
        message: '支付成功'
      });
    } catch {
      router.replace({ name: 'OrderList' });
    }
  }
}
